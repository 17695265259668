import React, { Component } from "react";
import { IPortfolio, IPortfolioImage } from "../types";
import ReactGA from "react-ga";
import ImageGallery from "react-image-gallery";

interface IPortfolioProps {
  data: IPortfolio;
}

interface IPortfolioState {
  images: IPortfolioImage[];
  currentProject: { url: string; title: string; description: string };
}

class Portfolio extends Component<IPortfolioProps, IPortfolioState> {
  constructor(props: IPortfolioProps) {
    super(props);

    this.state = {
      images: [],
      currentProject: { url: "", title: "", description: "" },
    };
  }

  setProject = (
    images: IPortfolioImage[],
    url: string,
    title: string,
    description: string
  ) => {
    images = images.map((image) => {
      return {
        thumbnail: "images/portfolio/projects/" + image.thumbnail,
        original: "images/portfolio/projects/" + image.original,
      };
    });
    this.setState({ images, currentProject: { url, title, description } });
  };

  render() {
    const self = this;
    if (this.props.data) {
      var projects = this.props.data.projects.map(function (projects) {
        var projectImage = "images/portfolio/" + projects.image;
        return (
          <div key={projects.title} className="columns portfolio-item">
            <a
              className={"smoothscroll"}
              style={{ cursor: "pointer" }}
              href={"#portfolio-project"}
              title={projects.title}
              onClick={() => {
                ReactGA.event({
                  category: "Portfolio",
                  action: projects.title,
                });
                self.setProject(
                  projects.images || [],
                  projects.url,
                  projects.title,
                  projects.category
                );
              }}
            >
              <div className="item-wrap">
                <img
                  alt={projects.title}
                  src={projectImage}
                  style={{ maxHeight: 143.23 }}
                />
                <div className="overlay">
                  <div className="portfolio-item-meta">
                    <h5>{projects.title}</h5>
                    <p>{projects.category}</p>
                  </div>
                </div>
                {/* <div className="link-icon">
                  <i style={{ marginTop: 50 }} className="fa fa-link"></i>
                </div> */}
              </div>
            </a>
          </div>
        );
      });
    }

    return (
      <section id="portfolio">
        <div className="row">
          <div className="twelve columns collapsed">
            <h1>Some of the projects I've been involved in.</h1>
            <h1>Click on any project to display pictures of it down below.</h1>
            <div
              id="portfolio-wrapper"
              className="bgrid-quarters s-bgrid-thirds cf"
            >
              {projects!}
            </div>
          </div>
        </div>
        <div id="portfolio-project" style={{ paddingTop: 40 }} />
        <a className="smoothscroll" href="#portfolio">
          {this.state.currentProject.title ? "▲ Go back to projects ▲" : ""}
        </a>
        {this.state.currentProject.title && (
          <div className="row">
            <a href={this.state.currentProject.url} target="_blank">
              <div className="link-icon">
                <i className="fa fa-link"></i>
                {" " + this.state.currentProject.title}
              </div>
            </a>
            {this.state.currentProject.description}
            {this.state.images.length !== 0 && (
              <ImageGallery items={this.state.images!} />
            )}
          </div>
        )}
      </section>
    );
  }
}

export default Portfolio;
