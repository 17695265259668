import React, { Component } from "react";
import { IMainComponentProps } from "../types";
import ReactGA from "react-ga";

class About extends Component<IMainComponentProps> {
  constructor(props: IMainComponentProps) {
    super(props);
  }

  render() {
    if (this.props.data) {
      var name = this.props.data.name;
      var profilepic = "images/" + this.props.data.image;
      var bio = this.props.data.bio;
      var street = this.props.data.address.street;
      var city = this.props.data.address.city;
      var state = this.props.data.address.state;
      var zip = this.props.data.address.zip;
      var phone = this.props.data.phone;
      var email = this.props.data.email;
      var resumeDownload = this.props.data.resumedownload;
    }

    return (
      <section id="about">
        <div className="row">
          <div className="three columns">
            <img
              className="profile-pic"
              src={profilepic!}
              alt="Mauricio Silva Vázquez Profile Pic"
            />
          </div>
          <div className="nine columns main-col">
            <h2>About Me</h2>

            <p>{bio!}</p>
            <div className="row">
              <div className="columns contact-details">
                <h2>Contact Details</h2>
                <p className="address">
                  <span>{name!}</span>
                  <br />
                  <span>
                    {city!}, {state!}, {zip!}
                  </span>
                  <br />
                  <span>{phone!}</span>
                  <br />
                  <span>
                    <a type={"email"} href={`mailto:${email!}`}>
                      {email!}
                    </a>
                  </span>
                </p>
              </div>
              <div className="columns download">
                <p>
                  <a
                    href={resumeDownload!}
                    className="button"
                    onClick={() => {
                      ReactGA.event({
                        category: "Resume",
                        action: "Download Resume",
                      });
                    }}
                  >
                    <i className="fa fa-download"></i>Download Resume
                  </a>
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default About;
