import React, { Component } from "react";
import ReactGA from "react-ga";
import $ from "jquery";
import "./App.css";
import Header from "./Components/Header";
import Footer from "./Components/Footer";
import About from "./Components/About";
import Resume from "./Components/Resume";
import Contact from "./Components/Contact";
import Testimonials from "./Components/Testimonials";
import Portfolio from "./Components/Portfolio";
import { IAppState } from "./types";

ReactGA.initialize("UA-181857355-1");

class App extends Component<any, IAppState> {
  constructor(props: any) {
    super(props);
    this.state = {
      resumeData: {
        data: {
          name: "Mauricio Silva Vázquez",
          occupation: "Software Engineer",
          description:
            "Passionate about exciting challenges and new technologies",
          image: "profilepic.jpg",
          bio: "Started out as a game developer at a young age, and because of this I fell in love with computers, development of applications and new technologies. Got an engineering's degree on computer science because I knew it's what I'm passionate about. In my free times I like to learn new things, such as a framework or an instrument. I even got several opportunities to get hired as a freelancer to create projects with different technologies such as React, React Native and Ruby on Rails.",
          contactmessage:
            "Here is where you should write your message to readers to have them get in contact with you.",
          email: "mausv97@gmail.com",
          phone: "+52 81 8185 2368",
          address: {
            street: "Colinas del Valle",
            city: "Monterrey, México",
            state: "N.L.",
            zip: "64650",
          },
          website: "https://www.mausv.com",
          resumedownload: "Resume%20-%20Mauricio%20Silva%20Vázquez.pdf",
          social: [
            {
              name: "facebook",
              url: "https://www.facebook.com/mauriciosilvavzz/",
              className: "fa fa-facebook",
            },
            {
              name: "twitter",
              url: "http://twitter.com/mausv",
              className: "fa fa-twitter",
            },
            {
              name: "instagram",
              url: "https://www.instagram.com/mausvzz/",
              className: "fa fa-instagram",
            },
            {
              name: "github",
              url: "https://github.com/mausv/",
              className: "fa fa-github",
            },
            {
              name: "linkedin",
              url: "https://www.linkedin.com/in/mausv",
              className: "fa fa-linkedin",
            },
          ],
        },
        resume: {
          education: [
            {
              school: "Universidad Regiomontana",
              degree: "Bachelor of Engineering in Computer Science",
              graduated: "2018",
              description: "Graduated top of the class",
            },
          ],
          work: [
            {
              company: "Pixel and Texel",
              title: "Senior Software Engineer",
              years: "2020 - Present",
              description: [
                "Full Stack - React, React Native, Firebase and NodeJS for Fresh Food Connect.",
                "Full Stack - Angular, .NET, Xamarin and Couchbase.",
                "Backend - Azure with Terraform."
              ],
            },
            {
              company: "Freelance",
              title: "Senior Software Engineer",
              years: "2014 - Present",
              description: [
                "Web development with React, Angular, Ruby on Rails and APIs with .NET Core and Firebase for several projects.",
                "Android/iOS development with React Native and Flutter - Uber-like app (pideloseguro.com), app to help workers find a job (jales.mx), app to help coaches give workout routines to their customers (workout.com.mx), app to track your personal maxes (Personal Max).",
                "CI/CD for automated builds for apps and websites with Azure DevOps that take the build from a pull request all the way to the stores/servers.",
                "Game development with Unity using JavaScript and C#.",
              ],
            },
            {
              company: "Dextra Technologies S.A. de C.V.",
              title: "Senior Software Engineer",
              years: "2020 - 2020",
              description: [
                "Working as a contractor for a US Company (J.J. Keller) using .NET stack with C#, ASP .NET, Windows Services, SQL Server and SSIS to develop and maintain functionality from several products of the company.",
                "Recognized as Top Performer within the first 3 months of starting."
              ],
            },
            {
              company: "EPICOR Software",
              title: "Software Applications Developer",
              years: "2018 - 2020",
              description: [
                "Maintaining ERP with .NET using C#, Windows Forms, SQL Server and LINQ in the finance team, fixing heavy performance issues and implementing new functionalities.",
              ],
            },
            {
              company: "Exgerm México S.A. de C.V.",
              title: "Software Developer",
              years: "2014 - 2018",
              description: [
                "Native mobile development with Java and SQL with Android to monitor electronic equipment. Webpage development with Python and Ruby on Rails for human resources and access to the facilities.",
              ],
            },
          ],
          skillmessage:
            "I use the technologies best suit for the job required and love learning new things",
          skills: [
            {
              name: "React & React Native",
              level: "95%",
            },
            {
              name: "Flutter",
              level: "90%",
            },
            {
              name: "NodeJS",
              level: "90%",
            },
            {
              name: "Angular",
              level: "80%",
            },
            {
              name: "JavaScript",
              level: "95%",
            },
            {
              name: "TypeScript",
              level: "93%",
            },
            {
              name: "C#/.NET",
              level: "90%",
            },
            {
              name: "Python",
              level: "80%",
            },
            {
              name: "Java",
              level: "80%",
            },
            {
              name: "SQL",
              level: "90%",
            },
            {
              name: "Git",
              level: "85%",
            },
            {
              name: "CI/CD",
              level: "85%",
            },
            {
              name: "Docker & Kubernetes",
              level: "80%",
            },
          ],
        },
        portfolio: {
          projects: [
            {
              title: "Personal Max",
              category:
                "App in Flutter to help track your personal maxes at gym or at home.",
              image: "personalmax.png",
              url: "https://apps.apple.com/gb/app/personal-max/id1605717595?platform=iphone/",
              images: [
                {
                  original: "PersonalMax/0.png",
                  thumbnail: "PersonalMax/0.png",
                },
                {
                  original: "PersonalMax/1.png",
                  thumbnail: "PersonalMax/1.png",
                },
                {
                  original: "PersonalMax/2.png",
                  thumbnail: "PersonalMax/2.png",
                },
                {
                  original: "PersonalMax/3.png",
                  thumbnail: "PersonalMax/3.png",
                },
                {
                  original: "PersonalMax/4.png",
                  thumbnail: "PersonalMax/4.png",
                },
                {
                  original: "PersonalMax/5.png",
                  thumbnail: "PersonalMax/5.png",
                },
                {
                  original: "PersonalMax/6.png",
                  thumbnail: "PersonalMax/6.png",
                },
                {
                  original: "PersonalMax/7.png",
                  thumbnail: "PersonalMax/7.png",
                },
              ],
            },
            {
              title: "Fresh Food Connect",
              category:
                "App in React Native, site in React with backend in NodeJS to help gardeners donate food. Deployed with Assembla CI/CD.",
              image: "ffc.png",
              url: "https://freshfoodconnect.org/",
              images: [
                {
                  original: "FFC/ffc.png",
                  thumbnail: "FFC/ffc.png",
                },
                {
                  original: "FFC/AppStore_1.png",
                  thumbnail: "FFC/AppStore_1.png",
                },
              ],
            },
            {
              title: "Jales MX",
              category:
                "App in React Native and site in React to help workers find a job. Deployed with Azure DevOps CI/CD.",
              image: "jales.jpg",
              url: "https://www.jales.mx",
              images: [
                {
                  original: "Jales/jales.jpg",
                  thumbnail: "Jales/jales.jpg",
                },
                {
                  original: "Jales/AppStore_1.png",
                  thumbnail: "Jales/AppStore_1.png",
                },
                {
                  original: "Jales/AppStore_2.png",
                  thumbnail: "Jales/AppStore_2.png",
                },
              ],
            },
            {
              title: "Pídelo Seguro",
              category: "Uber-like app in React Native.",
              image: "pideloseguro.jpg",
              url: "https://www.pideloseguro.com",
              images: [
                {
                  original: "PideloSeguro/AppStore_1.png",
                  thumbnail: "PideloSeguro/AppStore_1.png",
                },
                {
                  original: "PideloSeguro/AppStore_2.png",
                  thumbnail: "PideloSeguro/AppStore_2.png",
                },
                {
                  original: "PideloSeguro/1.jpg",
                  thumbnail: "PideloSeguro/1.jpg",
                },
                {
                  original: "PideloSeguro/2.jpg",
                  thumbnail: "PideloSeguro/2.jpg",
                },
                {
                  original: "PideloSeguro/3.jpg",
                  thumbnail: "PideloSeguro/3.jpg",
                },
                {
                  original: "PideloSeguro/4.jpg",
                  thumbnail: "PideloSeguro/4.jpg",
                },
                {
                  original: "PideloSeguro/5.jpg",
                  thumbnail: "PideloSeguro/5.jpg",
                },
                {
                  original: "PideloSeguro/6.jpg",
                  thumbnail: "PideloSeguro/6.jpg",
                },
                {
                  original: "PideloSeguro/7.jpg",
                  thumbnail: "PideloSeguro/7.jpg",
                },
                {
                  original: "PideloSeguro/8.jpg",
                  thumbnail: "PideloSeguro/8.jpg",
                },
                {
                  original: "PideloSeguro/9.jpg",
                  thumbnail: "PideloSeguro/9.jpg",
                },
                {
                  original: "PideloSeguro/10.jpg",
                  thumbnail: "PideloSeguro/10.jpg",
                },
                {
                  original: "PideloSeguro/11.jpg",
                  thumbnail: "PideloSeguro/11.jpg",
                },
                {
                  original: "PideloSeguro/12.jpg",
                  thumbnail: "PideloSeguro/12.jpg",
                },
              ],
            },
            {
              title: "Workout",
              category:
                "Workout app in React Native to provide routines easily from coaches to users.",
              image: "workout.jpg",
              url: "https://www.workout.com.mx",
              images: [
                {
                  original: "WKT/AppStore_1.png",
                  thumbnail: "WKT/AppStore_1.png",
                },
                {
                  original: "WKT/1.jpg",
                  thumbnail: "WKT/1.jpg",
                },
                {
                  original: "WKT/2.jpg",
                  thumbnail: "WKT/2.jpg",
                },
                {
                  original: "WKT/3.jpg",
                  thumbnail: "WKT/3.jpg",
                },
                {
                  original: "WKT/4.jpg",
                  thumbnail: "WKT/4.jpg",
                },
                {
                  original: "WKT/5.jpg",
                  thumbnail: "WKT/5.jpg",
                },
              ],
            },
            {
              title: "ONERP Punto de Venta",
              category: "A Point of Sale app.",
              image: "onerp.jpg",
              url: "https://apps.apple.com/mx/app/onerp-punto-de-venta/id1400947121",
              images: [
                {
                  original: "OneRP/AppStore_1.png",
                  thumbnail: "OneRP/AppStore_1.png",
                },
              ],
            },
            {
              title: "Trackiful",
              category:
                "A package-tracking website with React. Deployed with Azure DevOps CI/CD.",
              image: "trackiful.jpg",
              url: "https://trackiful.com",
              images: [
                {
                  original: "Trackiful/trackiful.jpg",
                  thumbnail: "Trackiful/trackiful.jpg",
                },
              ],
            },
            {
              title: "Splash Games Studio",
              category:
                "Game developer company. Launched a couple of games to the AppStore/Google Play.",
              image: "splashgs.jpg",
              url: "https://www.facebook.com/SplashGS",
              images: [
                {
                  original: "SplashGS/splashgs.jpg",
                  thumbnail: "SplashGS/splashgs.jpg",
                },
              ],
            },
            {
              title: "MauSV",
              category:
                "This site! Created with React and deployed with Azure DevOps CI/CD.",
              image: "mausvcom.jpg",
              url: "https://mausv.com",
              images: [
                {
                  original: "MauSV/mausvcom.jpg",
                  thumbnail: "MauSV/mausvcom.jpg",
                },
              ],
            },
          ],
        },
        testimonials: {
          testimonials: [
            {
              text: "Here you should write some nice things that someone has said about you. No lies though, employers can tell when you are lying.",
              user: "Kareem Abdul Jabbar",
            },
            {
              text: "That Mauricio Silva Vázquez must be one of the most brilliant developers I've ever met! It is amazing that nobody has hired him yet. Hey you, you should hire this guy, he may be fresh out of University and have zero on the job experience but I am confident that he will be one of your best developers in no time!",
              user: "Steve Wozniak... impersonator",
            },
          ],
        },
      },
    };
  }

  // getResumeData(){
  //   $.ajax({
  //     url:'/resumeData.json',
  //     dataType:'json',
  //     cache: false,
  //     success: (data: any) =>{
  //       this.setState({resumeData: data});
  //     }.bind(this),
  //     error: (xhr: any, status:any, err:any) =>{
  //       console.log(err);
  //       alert(err);
  //     }
  //   });
  // }

  componentDidMount() {
    // this.getResumeData();
    ReactGA.pageview(window.location.pathname + window.location.search);
  }

  render() {
    return (
      <div className="App">
        <Header data={this.state.resumeData.data} />
        <About data={this.state.resumeData.data} />
        <Resume data={this.state.resumeData.resume} />
        <Portfolio data={this.state.resumeData.portfolio} />
        {/* <Testimonials data={this.state.resumeData.testimonials} /> */}
        {/* <Contact data={this.state.resumeData.data} /> */}
        <Footer data={this.state.resumeData.data} />
      </div>
    );
  }
}

export default App;
